import React from 'react';
import './RadialChart.scss';

import {
    RadialBar,
    RadialBarChart,
    ResponsiveContainer,
} from 'recharts';



//used as gauge
const RadialChart = (props: any) => {
    const { value, max, color } = props;


    let perc = ((value / max) * 100);
    if (perc > 100) {
        perc = 100;
    }

    let kpicolor = 'red';
    if (perc < 34) {    // --danger
        kpicolor = '#e84855';
    } else if (perc > 66) {  // --success
        kpicolor = '#6FDB77';
    } else {  // --warning
        kpicolor = '#f3d13baa';
    };

    const data = [
        { name: '', value: max || 1, fill: 'none' },
        { name: `${perc}%`, value: value || 0, fill: color ?? kpicolor },
    ];


    return (
        <div className='radialChart'
        >
            <ResponsiveContainer width='99%' height='99%' aspect={2}>
                <RadialBarChart
                    data={data}
                    startAngle={180}
                    endAngle={0}

                    innerRadius="175%"
                    outerRadius="175%"
                    cy={'90%'}
                    barSize={20}
                >
                    <RadialBar
                        isAnimationActive={false}
                        background
                        dataKey='value'
                    />
                </RadialBarChart>
            </ResponsiveContainer>

            <span>{`${Number(perc.toFixed(2)).toLocaleString()}%`}</span>
        </div>
    );
};
export default React.memo(RadialChart);