import './ContextMenuContent.scss'; // import './css/ContextMenuContent.scss';


import React, { useCallback, useState } from 'react';

import Title from '../Title/Title';

import importer from '../../../core/helpers/importer';
// import {randomInRange} from '../../helpers/tools';


const ContextMenuContent: React.FC<any> = (props: any) => {

    const {
        options,
        class: classProp,
        data = null,
        style,
        // ref,
    } = props;



    let className = "contextMenuContent ";

    switch (props.variant) {
        case 'island': {
            className += 'island';
            break;
        }
        case 'std':
        default: {
            className += 'std';
            break;
        }
    }


    switch (props.anchor) {
        case 'left': {
            className += ' left';
            break;
        }
        case 'right':
        default: {
            className += ' right';
            break;
        }
    }

    if (props.class) {
        className = className + ' ' + classProp;
    }

    const { icon, title, actions } = props;


    const [test, setTest] = useState<string>('');
    // const test = useRef<string>('');

    const printMenuOption = useCallback((option: any, index: number, level: string | number) => {
        const lv: string = level.toString() + index;

        return (
            <div
                key={index}
                className={`option ${option.disabled ? 'disabled' : ''}`}
                onClick={() => {
                    if (option.onClick && !option.disabled && !option.subs) {
                        option.onClick(data);
                    }
                }}
                onMouseEnter={() => {
                    setTest(lv);
                }}
                onMouseLeave={() => {
                    setTest(lv.slice(0, -1));
                }}
            >
                <div className='label'>
                    {option.leftIcon &&
                        <div className='leftIcon'>
                            {option.leftIcon}
                        </div>
                    }
                    <div className="text">
                        {option.label}
                    </div>
                </div>
                {
                    (option.subs && option.subs.length > 0)
                        ?
                        <img className='arrow' src={importer.ic.require('chevronRight.svg')} alt="" />
                        :
                        (option.rightIcon &&
                            <div className='rightIcon'>
                                {option.rightIcon}
                            </div>
                        )
                }
                {
                    (option.subs && option.subs.length > 0) && lv === test.substring(0, lv.length)/*.current*/ &&
                    <div className='contextMenuContent subMenu'>
                        {
                            option.subs.map((o: any, i: number) => printMenuOption(o, i, lv))
                        }
                    </div>
                }
            </div>
        );
    }, [test/*, test.current*/]);


    return (
        <div
            ref={props.innerRef}
            className={className}
            style={{
                ...style
            }}
        >

            {(icon || title || actions) &&
                <div className='contextMenu-header'>
                    {(icon || title) &&
                        <div className='contextMenu-title'>
                            {icon &&
                                <div className="contextMenu-icon">
                                    {icon}
                                </div>
                            }

                            <Title>{title}</Title>
                        </div>
                    }
                    {actions &&
                        <div className='contextMenu-actions'>
                            {actions}
                        </div>
                    }
                </div>
            }
            <div className='contextMenu-body'>
                {options &&
                    options.map((option: any, index: number) => printMenuOption(option, index, index))
                }
            </div>
        </div>
    );
};

export default React.memo(ContextMenuContent);