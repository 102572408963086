import Fade from '@mui/material/Fade';
import { useLocation } from 'react-router-dom';
import config from '../../../core/config';

const GetOld = () => {
    const location = useLocation();

    return (
        <Fade in={true} timeout={{ enter: 400 }} >
            <iframe
                style={{ width: '100%', height: '100%', border: 'none' }}
                src={`${config.api.old}` + location.pathname}
            >
            </iframe>
        </Fade>
    );
};
export default GetOld;