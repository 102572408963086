import React, { useContext, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import './SplashScreen.scss';

import { ctxSession } from '../../../../core/store';

import config from '../../../../core/config';

import { SessionModel } from '../../../../core/models';
import { UserData_model } from '../../../../core/models/data/UserData_model';

import Axios from '../../../../core/helpers/axios';
import importer from '../../../../core/helpers/importer';

import { decodeToken } from 'react-jwt';


const SplashScreen = () => {

    const location = useLocation();

    const session = useContext(ctxSession);


    useEffect(() => {
        if (session && session.data && !session.data?.userData) {

            const onSuccess = (response: any) => {
                if (session && session.data && session.set) {
                    const { token } = response.data;
                    const decodedToken: UserData_model | null = decodeToken(token);

                    const sess: SessionModel = {
                        userData: decodedToken,
                        support: localStorage.getItem('support') === '1'
                    };

                    session.set(sess);
                }
            };

            const onError = (error: any) => {
                // localStorage.clear();
                // if(session && session.set){
                //     session.set(null)
                // }
                if (!error.response) {
                    if (error.code === 'ERR_NETWORK') {
                        setTimeout(canILog, 1000);
                    }
                }
                // console.log(error)
            };

            const canILog = () => {
                Axios(session, 'get', `${config.api.internal}/Auth/canILog`, onSuccess, onError);
            };

            canILog();
        }
    }, [session, location]); //React Hook useEffect has a missing dependency: 'session'

    return (
        <div id="SplashScreen">
            <img alt="Loading" src={importer.animated.require('loading.gif')} />
        </div>
    );
};
export default React.memo(SplashScreen);