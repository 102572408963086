import React, { useContext } from 'react';
import { decodeToken } from 'react-jwt';
import { useNavigate } from 'react-router-dom';
import config from '../../../../core/config/';
import Axios from '../../../../core/helpers/axios';
import Datetime from '../../../../core/helpers/datetime';
import formDataValidator from '../../../../core/helpers/formData';
import importer from '../../../../core/helpers/importer';
import { formatTimeSpan, getCookie } from '../../../../core/helpers/tools';
import language from '../../../../core/languages';
import './LogIn.scss';
//#region COMPONENTS
import Alert from '@mui/material/Alert';
import {
    Caption,
    Card,
    CustomButton,
    CustomCheckbox,
    CustomInput,
    Title
} from '../../../gest';
//#endregion
//#region ICONS
import EmailRoundedIcon from '@mui/icons-material/EmailRounded';
//#endregion
//#region MODELS
import { SessionModel } from '../../../../core/models';
import { UserData_model } from '../../../../core/models/data/UserData_model';
//#endregion
//CONTEXTS
import { ctxSession } from '../../../../core/store';

const LogIn = () => {
    const lang = language();

    const navigate = useNavigate();
    const session = useContext(ctxSession);

    const [loading, setLoading] = React.useState<boolean>(false);
    const [errorAnimation, setErrorAnimation] = React.useState<boolean | string>(false);
    const [askEmailCode, setAskEmailCode] = React.useState<boolean>(false);
    const [timer, setTimer] = React.useState<number>(0);

    const animateError = () => {
        setErrorAnimation(true);
        setTimeout(() => {
            setErrorAnimation(false);
        }, 100);
    };

    const checkForTimer = () => {
        const timer = getCookie("D_TIMER");
        const now = new Date();
        if (timer !== null) {
            const time_number = Number(timer);
            const time = new Date(time_number);
            if (time > now) {
                const seconds = Math.floor(time_number / 1000) - new Datetime().getUnixTimestamp();
                setTimer(seconds);
                return true;
            }
        }
        return false;
    };

    React.useEffect(() => {
        checkForTimer();
    }, [errorAnimation]);

    React.useEffect(() => {
        if (timer > 0) {
            setTimeout(() => {
                setTimer(timer - 1);
            }, 1000);
        }
    }, [timer]);


    const twoFactorCredentialsStore = React.useRef<any | null>(null);

    const logIn = (token: string) => {
        localStorage.clear();

        let userData: UserData_model | null = decodeToken(token);    // decodedToken

        if (userData?.email && userData?.role !== 'admin') {
            const onSuccess = (response: any) => {
                localStorage.setItem('support', '1');

                if (session && session.set) {
                    const sess: SessionModel = {
                        userData,
                        support: true
                    };

                    session?.set(sess);
                }
            };
            const onError = () => {
                localStorage.setItem('support', '0');

                if (session && session.set) {
                    const sess: SessionModel = {
                        userData,
                        support: false
                    };

                    session.set(sess);
                }
            };

            Axios(session, 'get', `${config.platforms.support}/WebGESTLogin.php`, onSuccess, onError, {}, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });

        } else if (userData?.role === 'admin') {
            localStorage.setItem('support', '1');

            if (session && session.set) {
                const sess: SessionModel = {
                    userData,
                    support: true
                };

                session.set(sess);
            }
        }
    };

    const logInSubmit = (e: any) => {
        e.preventDefault();

        /// Early return
        if (checkForTimer()) return;

        const formData: any = formDataValidator(e.target, 'object');

        if (formData.username && formData.password && formData.password.length >= 8) {
            twoFactorCredentialsStore.current = null;
            // setError(false);
            setLoading(true);


            const onSuccess = (response: any) => {
                const { token } = response.data;
                if (token) {
                    logIn(token);
                } else {
                    setLoading(false);
                    setAskEmailCode(true);

                    twoFactorCredentialsStore.current = formData;
                    // console.log('no Token');
                    return;
                }

            };

            const onError = (response: any) => {
                animateError();
                localStorage.clear();
                setLoading(false);
            };

            Axios(session, 'post', `${config.api.internal}/Auth/login`, onSuccess, onError, formData);
        }
        else {
            // setError('bad_credentials');
            animateError();
        }
    };

    const twoFactorLogInSubmit = (e: any) => {
        e.preventDefault();

        /// Early return
        if (twoFactorCredentialsStore.current === null) return;

        let formData: any = formDataValidator(e.target, 'object');
        formData = {
            ...formData,
            ...twoFactorCredentialsStore.current
        };

        if (formData.code && formData.code.length === 6) {
            const onSuccess = (response: any) => {
                const { token } = response.data;
                if (token) {
                    logIn(token);
                } else {
                    setLoading(false);
                }

            };

            const onError = (response: any) => {
                animateError();
                localStorage.clear();
                setLoading(false);
            };

            Axios(session, 'post', `${config.api.internal}/Auth/twoFactor`, onSuccess, onError, formData);
        }
        else {
            // setError('bad_credentials');
            animateError();
        }

    };


    return (
        <Card type="floating" id="Login" style={{ width: '350px' }}>
            <div className="logo">
                <img className={`mb-3 ${loading ? 'spin' : ''} ${errorAnimation ? 'shake' : ''} `} src={importer.img.require('Logo.svg')} alt="" />
                <h4 className='mb-0'>{lang.log_in_into_your_account}</h4>
            </div>
            <div className='my-4'>
                {timer > 0 &&
                    <div className='mb-2 text-danger'>
                        {lang.you_can_try_login_again_in(<b>{formatTimeSpan(timer)}</b>)}
                    </div>
                }
                {!askEmailCode ?
                    <form onSubmit={logInSubmit}>
                        <fieldset>
                            <CustomInput name="username" variant="cloud" img={importer.ic.require('user.svg')} placeholder={lang.username} class="mb-2" disabled={timer > 0} />
                            <CustomInput name="password" variant="cloud" minLength={8} type="password" img={importer.ic.require('password.svg')} placeholder={lang.password} showable={true} class="mb-2" disabled={timer > 0} />
                            <CustomCheckbox name="stay_logged_in" label={lang.stay_logged_in} />
                        </fieldset>

                        <div>
                            <CustomButton variant="primary" type={"submit"} class="w-100 mb-2">{lang.login}</CustomButton>
                            <a onClick={() => { navigate('/forgotPassword'); }}><strong>{lang.forgot_password}</strong></a>
                        </div>
                    </form>
                    :
                    <form onSubmit={twoFactorLogInSubmit}>
                        <Alert icon={false}>
                            <Title>{lang.device_not_registered}</Title>
                            <div className='my-1 w-100 justify-content-center'>
                                <EmailRoundedIcon fontSize='large' />
                            </div>
                            <Caption>{lang.check_email_for_code}</Caption>
                        </Alert>
                        <fieldset>
                            <CustomInput name="code" pattern="^\d+$" variant="cloud" img={importer.ic.require('password.svg')} placeholder={lang.code} class="mb-2" maxLength={6} />
                        </fieldset>
                        <div>
                            <CustomButton variant="primary" type={"submit"} class="w-100 mb-2">{lang.login}</CustomButton>
                            <a onClick={() => { navigate('/forgotPassword'); }}><strong>{lang.forgot_password}</strong></a>
                        </div>
                    </form>
                }
            </div>
        </Card>
    );
};
export default React.memo(LogIn);


// http://localhost:3001/