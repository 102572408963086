import React from 'react';
import "../GestChart.css";
import "./GestBarChart.css";
//MODELS
import { BarProps } from './BarProps';
//COMPONENTS
import {
	Bar,
	BarChart,
	CartesianGrid,
	LabelList,
	ResponsiveContainer,
	Tooltip,
	XAxis,
	YAxis
} from 'recharts';
import GestTooltip from '../../tooltip/GestTooltip';

//in display grid requires that row is minmax(0, 1fr)
const GestBarChart = (props: BarProps) =>
	<ResponsiveContainer className="gestBarChart" aspect={2}>
		<BarChart data={props.dataSource} margin={{ bottom: 15, top: 15 }}>
			<XAxis dataKey={props.x} />

			<YAxis tickFormatter={(num) => num >= 1000 ? `${(num / 1000).toFixed(0)}k` : num} hide={props.hideY} />

			<CartesianGrid strokeDasharray="3" vertical={false} />

			<Tooltip
				wrapperStyle={{ outline: "none" }}
				content={<GestTooltip {...{ payloadProp: props.bars.map((b) => ({ label: b.label, prop: b.y, spanColor: b.color })) }} />}
			/>

			{props.bars.map((b, i) =>
				<Bar
					isAnimationActive={false}
					key={`barChart${i}`}
					stackId="a"
					dataKey={b.y}
					fill={b.color}
					radius={i === props.bars.length - 1 ? [10, 10, 0, 0] : undefined}
				>
					{props.hideY && <LabelList dataKey={b.y} position="top" formatter={(value: number) => new Intl.NumberFormat('it-IT').format(value)} />}
				</Bar>
			)}
		</BarChart>
	</ResponsiveContainer >;
export default React.memo(GestBarChart);
