import universal from '../../universal';
import errors from './errors';


export default {
    created: "Creato",
    insert: "Inserito",
    not: "Non",
    show_hide: "Mostra/Nascondi",
    change: "Cambia",
    visibility: "Visibilità",
    polygon: "Poligono",
    make_active: "Attiva",
    make_deactivate: "Disattiva",
    enable: "Abilita",
    disable: "Disabilita",
    choose: "Scegli",
    select: "Seleziona",
    apply: "Applica",
    rows: "Rows",
    rows_visibility: "Visibilità Righe",
    existent: "Esistente",
    existents: "Esistenti",
    unexistents: "Inesistenti",
    interventions_list: "Lista Interventi",
    from: "da",
    to: "a",
    row: "Riga",
    rows: "Righe",
    elements: "Elementi",

    errors,
    universal,

    home: 'Homepage',
    test: 'Test',

    field_data_survey: 'Qualifiche',
    field_data_monitor: 'Monitor Qualifiche',
    export: 'Esporta',
    import: 'Importa',
    imported: 'Importato',
    fixed_network: 'Rete Fissa',
    import_export: 'Import/Export',
    infoworks_export: 'Esportazione Infoworks',
    dat_export: 'Esportazione Dat',


    network_analysis: 'Analisi Network',
    lab_analytics: 'Analitiche Laboratorio',
    performances: 'Prestazioni',
    trash_by_statistics: 'Statistiche Trash-By',

    monitor: 'Monitor',
    consumption_monitor: 'Monitor Consumi',
    district_monitor: 'Monitor Distretto',
    pressure_monitor: 'Monitor Pressioni',
    realtime_monitor: 'RealTime Monitor',

    operating_tools: 'Strumenti Operativi',
    user_registries: 'Anagrafiche Utente',
    route_analysis: 'Analisi Giri',
    logical_paths: 'Percorsi Logici',

    workforcemanagement: 'Workforce Management',
    replacement_interventions: 'Interventi di Sostituzione',
    planning: 'Pianificazione',

    synchronization: 'Sincronizzazione',
    qualifications_sync: 'SYNC Qualifiche',
    replacements_sync: 'SYNC Sostituzioni',
    walk_by_drive_by_sync: 'SYNC Walk-by/Drive-by',
    WFM_sync: 'SYNC WFM',
    work_force_management: 'Work Force Management',

    work_progress_status: 'Stato Avanzamento Lavori',




    "e-mail": "e-mail",
    wrong_email_password: "e-mail o password errati",
    login: "Accedi",
    username: "Username",
    password: "Password",
    log_in_into_your_account: "Accedi al tuo account",
    forgot_password: "Password dimenticata?",
    reset_password: "Reimposta password",
    reset: "Reimposta",
    back_to_login: "Torna al Login",
    password_request_completed: 'Richiesta di reset della password eseguita con successo.',
    check_your_inbox: 'Controlla la tua casella di posta.',
    didnt_receive_email: 'Non hai ricevuto l\'email?',  //Didn't receive the email?
    send_again: 'Invia di nuovo',
    repeat_password: "Ripeti password",
    password_reset_request_failed_or_expired: 'La richiesta di reset della password è fallita o scaduta.',
    password_reset_successfully: 'La password è stata reimpostata con successo!',
    logout: "Disconnetti",


    alarm: 'Allarme',
    alarms: 'Allarmi',
    alarmsDictionary: {
        no_alarm: 'NO ALARM',   //null
        BF: 'FLUSSO INVERSO',
        PE: 'PERSISTENZA',
        BX: 'BATTERIA',
        FM: 'SENSORE',
        TP: 'MANOMISSIONE',
        EX: 'CONSUMO ECCESSIVO',
        EM: 'CONSUMO ALTO',
        RS: 'AZZERAMENTO',
        DF: 'FLUSSO DIRETTO',
        TK: 'RIPRISTINO MANOMISSIONE',
        RT: 'RESET',
        CR: 'FIRMWARE ERROR',
        PM: 'MAGNETE PROGRAMMAZIONE',
        MG: 'MANOMISSIONE MAGNETICA',
        MC: 'MANOMISSIONE MECCANICA',
        RV: 'MONTATO AL CONTRARIO',
        AD: 'RILEVATA ARIA',
        BP: 'TUBATURA ROTTA',
        DR: 'ASCIUTTO',
        MT: 'METROLOGY',
        SS: 'SISTEMA',
        WQ: 'QUALITA ACQUA',
        PF: 'FERMO',
        LT: 'TEMPERATURA BASSA',
        PX: 'PERSISTENZA ECCESSIVA',
        PP: 'PERSISTENZA PASSATA',
        PI: 'PERDITA INTERMITTENTE',
        122: 'MOROSO',
        242: 'IN DISDETTA',
        342: 'DISDETTATO',
        306: 'RIMOSSO',
        132: 'ALLARME SCONOSCIUTO',
        116: 'SOSTITUITO',
        206: 'RIMOSSO',
        999: 'ALLARME SCONOSCIUTO',
        ICSE: 'IN CORSO DI CESSAZIONE',
        CNFA: 'CESSATA DA FATTURARE',
        CFAT: 'CESSATA FATTURATA',
        ICSO: 'IN CORSO DI SOSPENSIONE',
        SOSP: 'SOSPESO',
        1: 'STATO SERVIZIO 1',
        2: 'STATO SERVIZIO 2',
        3: 'STATO SERVIZIO 3',
        ES: 'TUBATURA VUOTA'
    },

    utilities: 'Utenze',
    meter: 'Meter',
    meters: 'Meters',
    smart_meter: 'Smart Meter Installati',
    non_smart: 'Non-Smart',
    towns: 'Comuni',
    municipalities: 'Comuni',
    valid_readings: 'Letture Valide',
    readings: 'Letture',
    active_users: 'Utenti attivi',

    brand: 'Marca',
    brands: 'Marche',

    communication: 'Comunicazione',
    communication_type: 'Tipo Comunicazione',

    device_type: 'Tipo Device',

    successfully_added: (val) => val ? val + ' aggiunto con successo!' : 'Aggiunto con successo!',
    successfully_updated: (val) => val ? val + ' aggiornato con successo!' : 'Aggiornato con successo!',
    successfully_removed: (val) => val ? val + ' rimosso con successo!' : 'Rimosso con successo!',
    successfully_created: (val) => val ? val + ' creato con successo!' : 'Creato con successo!',
    successfully_imported: (val) => val ? val + ' importato con successo!' : 'Importato con successo!',

    server_error: 'Errore del Server.',

    pick_a_date: 'Seleziona data',
    date_format: 'gg/mm/aaaa',
    confirm: 'Conferma',
    selected_days: 'Giorni selezionati',

    all_towns: 'Tutti i comuni',
    all_municipalities: 'Tutti i comuni',
    all: 'Tutti',

    failed_update_no_changes: `Aggiornamento non avvenuto.\r\nNon hai apportato modifiche al record.`,


    last_30_days: 'Ultimi 30 giorni',
    last_7_days: 'Ultimi 7 giorni',

    language: 'Lingua',
    back: 'Indietro',
    cancel: 'Annulla',

    really_want_logout: 'Vuoi davvero effettuare la disconnessione?',

    "Lavorazione Sospesa": "Lavorazione Sospesa",
    "Sostituito": "Sostituito",
    "Mancata Rimozione": "Mancata Rimozione",
    "Mancata Sostituzione": "Mancata Sostituzione",
    "Non Lavorato": "Non Lavorato",
    "Rimosso": "Rimosso",

    save: 'Salva',

    plan: 'Piano',


    installation: 'Installazione',
    repair: 'Riparazione',
    replacement: 'Sostituzione',
    removal: 'Rimozione',
    reading: 'Letturazione',
    georeferencing: 'Georeferenzazione',

    already_present: 'Già Presente',

    you_must_select_an_intervention_type: 'Devi prima selezionare un tipo di intervento',

    add_selected: 'Aggiungi Selezionati',
    remove_selected: 'Rimuovi Selezionati',

    empty_plan: 'Svuota Piano',

    unprocessed: 'Non Processato',
    attempted_processing: 'Tentato Processamento',
    suspended: 'Sospeso',
    processed: 'Processato',

    select_a_list: 'Seleziona una lista',
    select_operator: 'Seleziona operatore',

    identity_code: 'Codice Anagrafico',
    accountholder: 'Ragione Sociale',
    email: 'E-mail',
    telephone: 'Telefono',

    registry_details: 'Anagrafiche',
    intervention: 'Intervento',

    geo: 'Geo',
    geo_data: 'Dati Geografici',
    latitude: 'Latitudine',
    longitude: 'Longitudine',
    altitude: 'Altitudine',

    ciu: 'CIU', //codice identificativo unico
    supply_code: 'Codice Fornitura',

    intervention_type: 'Tipo di Intervento',
    intervention_status: 'Stato dell\'Intervento',

    old_brand: 'Vecchio Brand',
    new_brand: 'Nuovo Brand',
    old_serial: 'Vecchio Seriale',
    new_serial: 'Nuovo Seriale',
    old_reading: 'Lettura Vecchio',
    old_reading_date: 'Data Lettura Vecchio',
    new_reading: 'Lettura Nuovo',
    digits_number: 'Numero di Cifre',
    seal_code: 'Codice del Sigillo',
    valve: 'Valvola',

    anomaly: "Anomalia",
    anomaly_notes: "Note Anomalia",

    intervention_specifics: 'Specifiche Intervento',
    intervention_result: 'Risultato Intervento',
    result: 'Risultato',

    district: 'Distretto',
    address: 'Indirizzo',
    municipality: 'Comune',

    old_location: 'Posizione Vecchio',
    new_location: 'Posizione Nuovo',

    miscellaneous: 'Varie',

    causal: 'Causale',
    note: 'Nota',
    notes: 'Note',

    annotation: 'Annotazione',
    annotations: 'Annotazioni',

    all_plans: 'Tutti i Piani',
    edit_intervention: 'Modifica Intervento',

    exportation: 'Esportazione',
    importation: 'Importazione',

    preview: 'Anteprima',
    parameters: 'Parametri',

    select_exportation: 'Seleziona Esportazione',
    start_date: 'Data di Inizio',
    end_date: 'Data di Fine',
    status: 'Stato',
    date: 'Data',

    witness: 'Nome e Cognome Testimone',

    separator: 'Separatore',
    format: 'Formato',

    trash_by_tools: 'Strumenti Trash-By',
    trash_by_stats: 'Statistiche Trash-By',
    walk_by_drive_by_tools: 'Strumenti Walk-By/Drive-By',
    assignment: 'Assegnazione',
    district_metering: 'District Metering',
    geotool: 'Geotool',
    intervention_management: 'Gestione Interventi',

    press_shift_space_to_switch_working_mode: (...args) => <>Premi {args.length === 1 ? args : args.map((v, i) => (i < args.length - 1) ? <>{v}+</> : v)} per cambiare modalità di lavoro.</>,
    designer_mode: 'Modalità Designer',
    viewer_mode: 'Modalità Viewer',

    procedures: 'Procedure',

    add: 'Aggiungi',
    remove: 'Rimuovi',
    edit: 'Modifica',

    route_generator: 'Generatore di Percorsi Logici',
    house_number: 'Civico',
    serial_number: 'Numero Seriale',
    serial: 'Seriale',
    identification_number: 'Matricola',

    create_path: 'Crea Percorso',
    edit_path: 'Modifica Percorso',

    name: 'Nome',
    surname: 'Cognome',
    firstname: 'Nome',
    lastname: 'Cognome',

    operator: 'Operatore',
    anomaly: 'Anomalia',
    anomaly_notes: 'Note Anomalia',
    type: 'Tipo',
    supply_type: 'Tipo di Fornitura',

    intervention_date: 'Data Intervento',
    intervention_start_date: 'Data Inizio Intervento',
    intervention_end_date: 'Data Fine Intervento',

    all_photos: 'Tutte le Foto',

    gallery: 'Galleria',
    logs: 'Log',

    you_have_to_select_a_route: 'Devi selezionare un percorso!',

    ok: 'Ok',
    no: 'No',
    yes: 'Sì',

    follow_center: 'Segui il centro',
    generated_routes: 'Percorsi Generati',

    photo: 'Foto',
    photos: 'Foto',

    management: 'Gestione',

    administrator: 'Amministratore',
    admin: 'Amministratore',
    editor: 'Editor',
    viewer: 'Viewer',

    delete: 'Elimina',

    location: 'Posizione',
    group: 'Gruppo',
    odi: 'ODI', //ordine di intervento
    id: 'ID',

    period: 'Periodo',

    module: 'Modulo',


    end_users: 'Utenze',

    create_route: 'Crea percorso',
    by_changing_selection_lose_your_progress_proceed: 'Cambiando la selezione perderai tutti i tuoi progessi.\nProcedere?',

    delete_route: 'Elimina percorso',
    reset_routes: 'Reimposta percorsi',

    are_you_sure_wanna_delete_route: 'Sei sicuro di voler eliminare il percorso selezionato?',
    are_you_sure_wanna_reset_routes: 'Sei sicuro di voler eliminare tutti i percorsi generati?',
    are_you_sure_wanna_delete_this_photo: 'Sei sicuro di voler eliminare questa foto?',

    tips: 'Suggerimenti',
    press_ctrl_and_click_to_draw_selection_area: 'Premi Ctrl e Clicca per tracciare un area di selezione.',
    double_click_to_reset_selection_area: 'Fai doppio click per resettare l\'area di selezione.',

    notice_date: 'Data di notifica',

    end_user: 'Utenza',


    transmission: 'Trasmissione',
    transmissions: 'Trasmissioni',

    environment: 'Ambiente',
    newMeter: 'Nuovo Contatore',
    oldMeter: 'Vecchio Contatore',

    category: 'Categoria',
    select_a_category: 'Seleziona una Categoria',


    import_list: 'Importa Lista',


    lists: 'Liste',
    map: 'Mappa',

    accepted_files: 'File accettati',

    drag_and_drop_file_here: 'Trascina e rilascia un file qui', //Drag & Drop a File here

    kpi: 'KPI',


    stay_logged_in: 'Rimani connesso',

    code: 'Codice',
    device_not_registered: 'Dispositivo non registrato',
    check_email_for_code: 'Controlla la tua casella postale e inserisci il codice per poter accedere',

    you_can_try_login_again_in: (time) => `Potrai ritentare l'accesso tra ${time}`,

    yesterday: 'Ieri',
    realtime: 'Real time',

    red_meters: 'Meter letti',
    active_alarms: 'Allarmi attivi',

    visualization: 'Visualizzazione',
    visualization_type: (type) => `Visualizzazione (${type})`,

    reset_selection: 'Reimposta selezione',

    tabular: 'Tabellare',
    archive: 'Archivio',

    store: 'Archivia',
    stored: 'Archiviato',
    stored_s: 'Archiviati',
    restore: 'Ripristina',

    are_you_sure_wanna_delete_plan: 'Sei sicuro di voler eliminare il piano?',
    deleting_plan_info: 'Procedendo con la rimozione, le assegnazioni agli operatori verranno cancellate e non sarà più possibile ripristinare il piano.',
    are_you_sure_wanna_archive_plan: 'Sei sicuro di voler archiviare il piano?',
    archiving_plan_info: 'Procedendo con l\'archiviazione, le assegnazioni agli operatori verranno cancellate e non sarà più possibile visionare gli interventi.',
    are_you_sure_wanna_restore_plan: 'Sei sicuro di voler ripristinare il piano?',

    proceeding_operation_misalignments_exports: 'Procedendo con l\'operazione si potrebbe incorrere in disallineamenti nelle esportazioni.',//proceeding with the operation could result in misalignments in exports.

    warning: 'Attenzione',
    plans: 'Piani',

    plan_assignment: 'Assegnazione Piani',

    available: 'Disponibile',
    availables: 'Disponibili',

    stored_plans: 'Piani archiviati',

    assign_or_remove_plan_before_change_operator: <>Assegnare o Rimuovere i <span className='text-success'>piani</span> per l'Operatore corrente prima di poter modificare la selezione.</>,
    selected_plan_assigned_operator_remove_proceed: <>Il <span className='text-danger'>piano</span> selezionato è attualmente assegnato ad un Operatore. Selezionare "Rimuovi" per procedere.</>,

    plans_assigned_to_operator: (name) => name ? `Piani assegnati all'operatore ${name}` : 'Piani assegnati all\'operatore',
    assign_plans: 'Assegna piani',

    are_you_sure_wanna_delete_list: 'Sei sicuro di voler eliminare la lista?',
    assigned: 'Assegnato',
    unassigned: 'Non Assegnato',

    empty_selection: 'Svuota selezione',
    create_new: 'Crea nuovo',
    add_move: 'Aggiungi/Sposta',
    interventions: 'Interventi',
    selected: 'Selezionato',
    selected_s: 'Selezionati',
    move: 'Sposta',

    list_name: 'Nome lista',
    plan_name: 'Nome Piano',

    diameter: 'Diametro',
    diameter_mm: 'Diametro (mm)',

    seal: 'Sigillo',

    //Customizzazione InterventionManagementATENA
    provincia: 'Provincia',
    province: 'Province',
    posizione_contatore: 'Posizione contatore',
    luogo_contatore: 'Luogo contatore',
    SF_distribution: 'Distribuzione Spreading Factor'
};