import axios from 'axios';
import moment from "moment";
import React from 'react';
import config from '../../../core/config';
import importer from '../../../core/helpers/importer';
import {
    ASC, centerCalculator, getCurrentISOWeek,
    getPreviousISOWeek,
    globalCenter, slottedColor
} from '../../../core/helpers/tools';
import language from '../../../core/languages';
import './Dashboard.scss';
//#region COMPONENTS
import { MenuItem, Select, Tooltip } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import Fade from '@mui/material/Fade';
import Skeleton from '@mui/material/Skeleton';
import { GoogleMap, InfoWindow, Marker, Polygon } from '@react-google-maps/api';
import { ReactSVG } from "react-svg";
import {
    Card,
    ContextMenu,
    CustomButton,
    CustomInput,
    CustomModal,
    CustomSelect,
    CustomTab,
    CustomTable,
    RowStat,
    Title,
    ValueOnMax
} from '../../gest';
import RadialChart from '../../gest/charts/RadialChart/RadialChart';
import GestBarChart from '../../gest/charts/bar/GestBarChart';
//#endregion
//ICONS
import ExitToAppRoundedIcon from '@mui/icons-material/ExitToAppRounded';
//MODELS
import { Coordinates } from '../../../core/models/data/Coordinates';
import { CustomSelectOption } from '../../gest/CustomSelect/models';
//CONTEXTS
import { ctxSession, ctxSettings, ctxSnackbar } from '../../../core/store';

//#region MODELS DEF
export interface ITimeSeries {
    x: number | string | Date;
    y: number | string | boolean;
}

interface ISFChart {
    SF07?: number;
    SF08?: number;
    SF09?: number;
    SF10?: number;
    SF11?: number;
    SF12?: number;
}

interface IBrand {
    type: string;
    count: number;
    perc?: number;
}

interface IGateway {
    siteId: string;
    indirizzo: string;
    macAddress: string;
    lat: number;
    lng: number;
    palo: string;
    sn: string;
    ipSim: string;
    host: string;
    idStato: number;
}

interface ICityStats extends ISFChart {
    id: number;
    endUsers: number;
    activeUsers: number;
    smartMeters: number;
    nonSmart: number;
    userCount: number;
    readingCount: number;
    percTelegestitiSuTotMeter: number;
    percTelelettiSuTotMeter: number;
    NMeterTeleGestiti: number;
    NMeterTeleLetti: number;
    nonRedMeters: number;
    brands: IBrand[],
}

interface IKeyValue {
    [key: string]: string | number | boolean | Date;
}

interface IKpiExport extends IKeyValue {
    week: number;
}

interface ICity {
    id: number;
    geoAreaZIndex?: number;
    geoLabelLat?: number;
    geoLabelLng?: number;

    name: string;
    province: string;

    coords: Coordinates;
    geoArea: Coordinates[];
    stats?: ICityStats;
}
//#endregion

const Dashboard = () => {
    //#region CONTEXTS
    const session = React.useContext(ctxSession);
    const settings = React.useContext(ctxSettings);
    const snackbar = React.useContext(ctxSnackbar);
    // const snackbar = React.useContext(ctxSnackbar);
    const lang = language(settings?.data?.lang);
    //#endregion

    //#region STATIC DATA
    document.title = config.app.name;

    let debounceTimer: NodeJS.Timeout | undefined;
    const menu = JSON.parse(localStorage.getItem('menu') ?? '[]');
    const defaultAllSelected = -1;
    const defaultStats: ICityStats = {
        id: defaultAllSelected,
        endUsers: 0,
        activeUsers: 0,
        smartMeters: 0,
        nonSmart: 0,
        userCount: 0,
        readingCount: 0,
        percTelegestitiSuTotMeter: 0,
        percTelelettiSuTotMeter: 0,
        NMeterTeleGestiti: 0,
        NMeterTeleLetti: 0,
        nonRedMeters: 0,
        brands: [],
    };
    const defaultChart: ISFChart = {
        SF07: 0,
        SF08: 0,
        SF09: 0,
        SF10: 0,
        SF11: 0,
        SF12: 0,
    };
    //#endregion

    //#region STATES
    //MAP
    const [mapCenter, setMapCenter] = React.useState<Coordinates>();
    const [cityLabel, setCityLabel] = React.useState<any | null>(null);
    //PROVINCE-CITY
    const [provinces, setProvinces] = React.useState<CustomSelectOption[]>([]);
    const [cities, setCities] = React.useState<ICity[]>([]);
    const [citiesInProvince, setCitiesInProvince] = React.useState<ICity[]>([]);

    const [selectedProvince, setSelectedProvince] = React.useState<number>(defaultAllSelected);
    const [selectedCity, setSelectedCity] = React.useState<number>(defaultAllSelected);
    const [currTab, setCurrTab] = React.useState<number>(0);
    //EXPORT
    const [kpiExportPreview, setKpiExportPreview] = React.useState<IKpiExport[]>([]);
    const [exportYear, setExportYear] = React.useState<number>(new Date().getFullYear());
    const [exportKpi, setExportKpi] = React.useState<"managed" | "managedOnTotalPerc" | "read" | "readOnTotalPerc">("read");
    //STATS
    const [mapStats, setMapStats] = React.useState<ICityStats>(defaultStats);
    const [SFChart, setSFChart] = React.useState<ISFChart>(defaultStats as ISFChart);
    //GATEWAYS
    const [gateways, setGateways] = React.useState<IGateway[]>([]);
    const [clickedGatewayMarker, setClickedGatewayMarker] = React.useState<IGateway>();
    //WEEKS
    const [selectedStatDay, setSelectedStatDay] = React.useState<string>(getPreviousISOWeek());
    const [selectedPerc, setSelectedPerc] = React.useState<'percTelelettiSuTotMeter' | 'percTelegestitiSuTotMeter'>('percTelelettiSuTotMeter');
    const [positionCM, setCMPosition] = React.useState<any | null>(null);
    const [CMData, setCMData] = React.useState<any | null>(null);
    //FLAGS
    const [loadingExport, setExportLoading] = React.useState(false);
    const [loadingGateways, setGatewaysLoading] = React.useState(true);
    const [loadingCities, setCitiesLoading] = React.useState(true);
    const [loadingCitiesStats, setCitiesStatsLoadings] = React.useState(false);

    const [showExportModal, setShowExportModal] = React.useState<boolean>(false);
    const [showRightClickMenu, setShowRightClickMenu] = React.useState<boolean>(false);
    const [showMapStatsDetails, setShowMapStatsDetails] = React.useState<boolean>(false);
    const [showGateways, setShowGateways] = React.useState<boolean>(false);
    //#endregion

    //#region METHODS
    //#region API CALLS
    const getCitiesAPI = () => axios.get<ICity[]>(`${config.api.internal}/Dashboard/Cities`, { withCredentials: true })
        .then(res => {
            const initCities = res.data;

            let provinces: CustomSelectOption[] = [];
            initCities.forEach((c, k) => {
                if (c.province && !provinces.some(p => p.label === c.province)) {
                    provinces.push({ value: k, label: c.province });
                }
            });

            getCitiesStatsAPI(initCities);
            setMapCenter(globalCenter(initCities));
            setCities(initCities);
            setCitiesInProvince(initCities);
            setProvinces(provinces);
        })
        .catch((err) => console.error(err))
        .finally(() => setCitiesLoading(false));

    const getCitiesStatsAPI = (initCities?: ICity[]) => {
        setCitiesStatsLoadings(true);
        return axios.get<ICityStats[]>(`${config.api.internal}/Dashboard/Cities/Stats/${selectedStatDay}`, { withCredentials: true })
            .then(res => {
                const stats = res.data;

                const currCities = initCities ?? cities;
                if (currCities.length) {
                    const withStats = currCities.map<ICity>(c => ({
                        ...c,
                        stats: stats.find(s => s.id === c.id)
                    }));

                    setCities(withStats);
                    updatedMapStats(withStats);
                }
            })
            .catch(err => console.error(err))
            .finally(() => setCitiesStatsLoadings(false));
    };

    const getKpiExportAPI = () => {
        setExportLoading(true);

        return axios.get<IKpiExport[]>(`${config.api.internal}/Dashboard/Cities/Kpi/${exportKpi}/Export/${exportYear}`, { withCredentials: true })
            .then(res => {
                const preview = res.data;
                setKpiExportPreview(preview);
                return preview;
            })
            .catch((err) => {
                console.error(err);
                return undefined;
            })
            .finally(() => setExportLoading(false));
    };

    const getGatewaysAPI = () => axios.get<IGateway[]>(`${config.api.internal}/Dashboard/Gateways`, { withCredentials: true })
        .then(res => setGateways(res.data))
        .catch((err) => console.error(err))
        .finally(() => setGatewaysLoading(false));
    //#endregion

    const handleMouseMove = (area: ICity, coords: any) => { //  debounce dell'evento
        if (debounceTimer) {
            clearTimeout(debounceTimer);
            debounceTimer = undefined;
        }

        if (!debounceTimer && !cityLabel) {
            debounceTimer = setTimeout(() => {
                setCityLabel({
                    id: area.id,
                    label: area.name,
                    position: coords // || centerCalculator(area.geoArea)
                });
                clearTimeout(debounceTimer);
                debounceTimer = undefined;
            }, 375); // 375 millisecondi di ritardo
        }
    };

    const handleMouseOut = () => {
        if (debounceTimer) {
            clearTimeout(debounceTimer);
            debounceTimer = undefined;
        }
        setCityLabel(null);
    };

    const findPerm = (areaID: number, sectionID: number) => {
        const area = menu.find((v: any) => v.id === areaID);
        const section = area?.subs.some((v: any) => v.custom === false && v.id === sectionID && v.enabled === true);

        return area && section;
    };

    const updatedMapStats = (initCities?: ICity[], selected?: number) => {
        const currCities = initCities ?? (citiesInProvince.length && citiesInProvince.length < cities.length ? citiesInProvince : cities);
        const currCity = selected ?? selectedCity;

        let updatedStats = structuredClone(defaultStats);
        let chart = structuredClone(defaultChart);
        let center: Coordinates | undefined = undefined;

        if (currCity !== -1) {
            //get stats from selected city
            const currentCity = currCities.find(c => c.id === currCity);

            if (currentCity?.stats) {
                updatedStats.id = currentCity.id;
                updatedStats.endUsers = (currentCity.stats.smartMeters + currentCity.stats.nonSmart);
                updatedStats.activeUsers = currentCity.stats.activeUsers;
                updatedStats.smartMeters = currentCity.stats.smartMeters;
                updatedStats.nonSmart = currentCity.stats.nonSmart;
                updatedStats.userCount = currentCity.stats.userCount;
                updatedStats.readingCount = currentCity.stats.readingCount;
                updatedStats.percTelegestitiSuTotMeter = currentCity.stats.percTelegestitiSuTotMeter;
                updatedStats.percTelelettiSuTotMeter = currentCity.stats.percTelelettiSuTotMeter;
                updatedStats.NMeterTeleGestiti = currentCity.stats.NMeterTeleGestiti;
                updatedStats.NMeterTeleLetti = currentCity.stats.NMeterTeleLetti;
                updatedStats.nonRedMeters = currentCity.stats.smartMeters - currentCity.stats.NMeterTeleLetti;
                updatedStats.brands = currentCity.stats.brands
                    .map<IBrand>(b => ({
                        ...b,
                        perc: parseFloat(((b.count / (currentCity.stats!.smartMeters + currentCity.stats!.nonSmart)) * 100).toFixed(2))
                    }))
                    .sort(ASC('type'));

                chart = structuredClone(currentCity.stats as ISFChart);
                center = centerCalculator(currentCity.geoArea);
            }
        }
        else {
            //get stats from all
            let allBrands: IBrand[] = [];

            currCities
                .filter(c => c.stats)
                .forEach(c => {
                    updatedStats.id = -1;
                    updatedStats.endUsers += (c.stats!.smartMeters + c.stats!.nonSmart);
                    updatedStats.activeUsers += c.stats!.activeUsers;
                    updatedStats.smartMeters += c.stats!.smartMeters;
                    updatedStats.nonSmart += c.stats!.nonSmart;
                    updatedStats.userCount += c.stats!.userCount;
                    updatedStats.readingCount += c.stats!.readingCount;
                    updatedStats.percTelegestitiSuTotMeter += c.stats!.percTelegestitiSuTotMeter;
                    updatedStats.percTelelettiSuTotMeter += c.stats!.percTelelettiSuTotMeter;
                    updatedStats.NMeterTeleGestiti += c.stats!.NMeterTeleGestiti;
                    updatedStats.NMeterTeleLetti += c.stats!.NMeterTeleLetti;

                    chart.SF07! += c.stats?.SF07 ?? 0;
                    chart.SF08! += c.stats?.SF08 ?? 0;
                    chart.SF09! += c.stats?.SF09 ?? 0;
                    chart.SF10! += c.stats?.SF10 ?? 0;
                    chart.SF11! += c.stats?.SF11 ?? 0;
                    chart.SF12! += c.stats?.SF12 ?? 0;

                    c.stats!.brands.forEach(b => {
                        const index = allBrands.findIndex(v => v.type === b.type);

                        if (index >= 0) {
                            //increment count
                            allBrands[index].count += b.count;
                        }
                        else {
                            //add
                            allBrands.push(structuredClone(b));
                        }
                    });
                });

            updatedStats.percTelegestitiSuTotMeter = parseFloat(((updatedStats.percTelegestitiSuTotMeter / updatedStats.activeUsers) * 100).toFixed(2));
            updatedStats.percTelelettiSuTotMeter = parseFloat(((updatedStats.percTelelettiSuTotMeter / updatedStats.activeUsers) * 100).toFixed(2));
            updatedStats.nonRedMeters = updatedStats.smartMeters - updatedStats.NMeterTeleLetti;
            updatedStats.brands = allBrands
                .map<IBrand>(b => ({
                    ...b,
                    perc: (b.count / (updatedStats.smartMeters + updatedStats.nonSmart)) * 100
                }))
                .sort(ASC('type'));

            const filteredAreas = currCities.filter(c => c.stats?.NMeterTeleGestiti || c.stats?.NMeterTeleLetti);
            center = globalCenter(filteredAreas.length ? filteredAreas : currCities);
        }

        setMapCenter(center);
        setSFChart(chart);
        setMapStats(updatedStats);
    };

    const hideUnselectedProvinces = (selectedProv: number) => {
        let currCities: ICity[] = structuredClone(cities);
        let currCity = selectedCity;

        if (selectedProv !== defaultAllSelected) {
            currCities = cities.filter(c => c.province && provinces.some(p => p.value === selectedProv && c.province === p.label));
            currCity = currCities.some(c => c.id === selectedCity) ? selectedCity : defaultAllSelected;
        }

        setSelectedProvince(selectedProv);
        setCitiesInProvince(currCities);
        updatedMapStats(currCities, currCity);
    };

    const renderPolygonColor = (stats?: ICityStats) => {
        let color = 'rgb(127, 127, 127)';//green

        if (stats && (stats.percTelelettiSuTotMeter >= 0 || stats.percTelegestitiSuTotMeter >= 0)) {
            color = `rgb(${slottedColor(selectedPerc === 'percTelelettiSuTotMeter'
                ? stats.percTelelettiSuTotMeter
                : stats.percTelegestitiSuTotMeter
            ).join(',')})`;
        }

        return color;
    };

    const downloadExportExcel = async () => {
        let preview = structuredClone(kpiExportPreview);

        try {
            if (!kpiExportPreview.length) {
                const res = await getKpiExportAPI();

                if (res) {
                    preview = structuredClone(res);
                }
            }

            setExportLoading(true);
            const header = ["week", ...cities.map(c => c.name.toUpperCase())];
            const XLSX = require("xlsx");
            const sheet = XLSX.utils.json_to_sheet(preview, { header });
            const book = XLSX.utils.book_new();
            const name = `${moment(new Date()).format("YYYYMMDD_HHmmss")}_Tele${exportKpi === "managed" ? "Gestiti" : "Letti"}`;
            const format = "xlsx";

            XLSX.utils.book_append_sheet(book, sheet, name);

            const excel = XLSX.write(book, { bookType: format, type: "array" });
            const blob = new Blob([excel], { type: "application/octet-stream" });

            const url = URL.createObjectURL(blob);
            const link = document.createElement("a");

            link.href = url;
            link.download = `${name}.${format}`;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);

            URL.revokeObjectURL(url);
        }
        catch (err) {
            console.error(err);
            snackbar?.set({ message: "Errore durante download dell'esportazione", severity: "error" });
        }
        finally {
            setExportLoading(false);
        }
    };
    //#endregion

    //#region STATE CHANGE
    React.useEffect(() => {
        getGatewaysAPI();
        getCitiesAPI();
    }, []);

    React.useEffect(() => { getCitiesStatsAPI(); }, [selectedStatDay]);

    React.useEffect(updatedMapStats, [selectedCity]);
    //#endregion

    return (
        <Fade in={true} timeout={{ enter: 400 }}>
            <div id="home">
                <main>
                    <Card class="map">
                        <div className="mapBox">
                            <GoogleMap
                                mapContainerStyle={{ width: '100%', height: '100%', borderRadius: "10px" }}
                                center={mapCenter || { lat: 45.306622, lng: 10.416617 }}
                                zoom={selectedCity !== -1 ? 12 : 10}
                                options={{ minZoom: 3, fullscreenControl: true }}
                            >
                                {(citiesInProvince.length && citiesInProvince.length < cities.length ? citiesInProvince : cities)
                                    .filter(c => c.geoArea)  //  Polygons
                                    .map((c, i) => <Polygon
                                        key={i}
                                        paths={c.geoArea}
                                        options={{
                                            fillColor: renderPolygonColor(c.stats),
                                            fillOpacity: .5,
                                            strokeColor: c.id === selectedCity ? '#063044' : '#fff',
                                            strokeOpacity: c.id === selectedCity ? 1 : .67,
                                            strokeWeight: c.id === selectedCity ? 2 : 1,
                                            zIndex: c.id === selectedCity ? 16 : c.geoAreaZIndex
                                        }}
                                        onRightClick={(e: any) => {
                                            const { offsetX, offsetY } = e.domEvent;
                                            setCMPosition({ x: offsetX, y: offsetY });
                                            setShowRightClickMenu(true);
                                            setCMData(c);
                                        }}
                                        onClick={() => setSelectedCity(selectedCity === c.id ? -1 : c.id)}
                                        onMouseMove={(e) => {
                                            if (e.latLng) {
                                                const coords: Coordinates = {
                                                    lat: e.latLng.lat(),
                                                    lng: e.latLng.lng()
                                                };
                                                handleMouseMove(c, coords);
                                            }
                                        }}
                                        onMouseOut={handleMouseOut}
                                    />
                                    )}

                                {showGateways && gateways.map(g =>
                                    <Marker
                                        onClick={() => setClickedGatewayMarker(g)}
                                        position={g as Coordinates}
                                        icon={importer.ic.require("markerRoundedBlue.png")}
                                    />
                                )}

                                {clickedGatewayMarker &&
                                    <InfoWindow
                                        onCloseClick={() => setClickedGatewayMarker(undefined)}
                                        position={clickedGatewayMarker as Coordinates}
                                        zIndex={3}
                                        options={{
                                            minWidth: 250,
                                            pixelOffset: new google.maps.Size(0, -20)
                                        }}
                                    >
                                        <div id="gatewayWindow">
                                            {Object
                                                .keys(clickedGatewayMarker)
                                                .filter(k => (clickedGatewayMarker as any)[k] && !k.toLowerCase().includes("lat") && !k.toLowerCase().includes("lng"))
                                                .map(k => <RowStat label={lang[k] ?? k} value={(clickedGatewayMarker as any)[k]} />)}
                                        </div>
                                    </InfoWindow>
                                }

                                {showMapStatsDetails &&
                                    (citiesInProvince.length && citiesInProvince.length < cities.length ? citiesInProvince : cities)
                                        .filter(c => c.geoArea && c.geoLabelLat && c.geoLabelLng)
                                        .map((c, i) => c.stats?.percTelelettiSuTotMeter ?? 0 >= 0 ?
                                            <InfoWindow
                                                key={i}
                                                position={{ lat: c.geoLabelLat!, lng: c.geoLabelLng! }}
                                                zIndex={1}
                                            >
                                                <span className='mapLabel stat'>
                                                    <div className='value'>
                                                        {Math.ceil((selectedPerc === 'percTelelettiSuTotMeter' ? c.stats?.percTelelettiSuTotMeter : c.stats?.percTelegestitiSuTotMeter) ?? 0)}%
                                                    </div>
                                                </span>
                                            </InfoWindow>
                                            :
                                            <></>
                                        )
                                }

                                {cityLabel &&
                                    <InfoWindow
                                        position={{ lat: cityLabel.position.lat, lng: cityLabel.position.lng }}
                                        zIndex={2}
                                    >
                                        <span className='mapLabel'
                                            onClick={() => {
                                                if (selectedCity !== -1 && selectedCity === cityLabel.id) setSelectedCity(-1);
                                                else setSelectedCity(cityLabel.id);
                                            }}
                                        >
                                            {cityLabel.label}
                                        </span>
                                    </InfoWindow>
                                }

                                <ContextMenu
                                    options={[
                                        /*  NETWORK */
                                        {
                                            leftIcon: <ReactSVG src={importer.ic.require('network_analysis_option_Blue.svg')} />,
                                            label: lang.network_analysis,
                                            rightIcon: <ExitToAppRoundedIcon style={{ fill: 'var(--primary)' }} />,
                                            onClick: () => {
                                                if (CMData) {
                                                    localStorage.setItem('networkAnalisysCity', CMData.id);
                                                    window.open('/networkAnalysis', "_blank");
                                                }
                                                setShowRightClickMenu(false);
                                            },
                                            disabled: !findPerm(1, 2),
                                        },
                                        {
                                            leftIcon: <ReactSVG src={importer.ic.require('consumption_monitor_Blue.svg')} />,
                                            label: lang.consumption_monitor,
                                            rightIcon: <ExitToAppRoundedIcon style={{ fill: 'var(--primary)' }} />,
                                            onClick: () => {
                                                if (CMData) {
                                                    localStorage.setItem('consumptionnMonitorCity', CMData.id);
                                                    window.open('/consumptionnMonitor', "_blank");
                                                }
                                                setShowRightClickMenu(false);
                                            },
                                            disabled: !findPerm(1, 2),
                                        },
                                    ]}
                                    position={positionCM}
                                    isOpened={showRightClickMenu}
                                    data={CMData}
                                    onClose={() => {
                                        setCMData(null);
                                        setShowRightClickMenu(false);
                                    }}
                                    title={CMData ? CMData.name : ''}
                                >
                                </ContextMenu>
                            </GoogleMap>

                            <Card class='legend' title={'kpi'}>
                                <span>{'> 90% & <= 100%'}</span>
                                <span>{'> 80% & <= 90%'}</span>
                                <span>{'> 70% & <= 80%'}</span>
                                <span>{'> 50% & <= 70%'}</span>
                                <span>{'>= 0% & <= 50%'}</span>
                            </Card>

                            <div className='periodSelector'>
                                {loadingCitiesStats && <CircularProgress />}

                                <CustomInput
                                    value={selectedStatDay}
                                    type='week'
                                    max={getCurrentISOWeek()}
                                    onChange={(v) => setSelectedStatDay(v as string)}
                                    disabled={loadingCities || loadingCitiesStats}
                                    style={{ width: 200 }}
                                />

                                {showMapStatsDetails &&
                                    <CustomSelect
                                        style={{ width: 150 }}
                                        value={selectedPerc}
                                        options={[{
                                            value: 'percTelelettiSuTotMeter',
                                            label: <><img src={importer.ic.require('TeleLetti.svg')} /> Tele-Letti</>
                                        },
                                        {
                                            value: 'percTelegestitiSuTotMeter',
                                            label: <><img src={importer.ic.require('TeleGestiti.svg')} /> Tele-Gestiti</>
                                        }]}
                                        onChange={(v: any) => setSelectedPerc(v)}
                                    />
                                }
                            </div>

                            <div className="tools">
                                <Tooltip title="Mostra Percetuali KPI" placement='left'>
                                    <div
                                        className={`mb-1 mapBtn showMapStatsDetails ${showMapStatsDetails ? 'active' : ''}`}
                                        onClick={() => setShowMapStatsDetails(!showMapStatsDetails)}
                                    >
                                        {loadingCitiesStats
                                            ? <CircularProgress size="20px" />
                                            : <ReactSVG src={importer.ic.require('kpi.svg')} />
                                        }
                                    </div>
                                </Tooltip>

                                <Tooltip title="Mostra Gateways" placement='left'>
                                    <div
                                        className={`mapBtn showMapStatsDetails ${showGateways ? 'active' : ''}`}
                                        onClick={() => setShowGateways(!showGateways)}
                                    >
                                        {loadingGateways
                                            ? <CircularProgress size="20px" />
                                            : <ReactSVG src={importer.ic.require('gateway.svg')} />
                                        }
                                    </div>
                                </Tooltip>
                            </div>
                        </div>

                        <div className='mapStats'>
                            <Card>
                                <div className='stats'>
                                    <div className='values'>
                                        <Title>{lang.end_users}</Title>
                                        <h3>{mapStats.endUsers !== undefined ? mapStats.endUsers.toLocaleString() : <Skeleton />}</h3>
                                    </div>

                                    <div className="icon">
                                        <img src={importer.ic.require('utentiAttivi.svg')} alt="" />
                                    </div>
                                </div>
                            </Card>

                            <Card>
                                <div className='stats'>
                                    <div className='values'>
                                        <Title>{lang.smart_meter}</Title>
                                        <h3>{mapStats.smartMeters !== undefined ? mapStats.smartMeters.toLocaleString() : <Skeleton />}</h3>
                                    </div>

                                    <div className="icon">
                                        <img src={importer.ic.require('smartMeter.svg')} alt="" />
                                    </div>
                                </div>
                            </Card>

                            <Card>
                                <div className='stats'>
                                    <div className='values'>
                                        <Title>{lang.non_smart}</Title>
                                        <h3>{mapStats.nonSmart !== undefined ? mapStats.nonSmart.toLocaleString() : <Skeleton />}</h3>
                                    </div>

                                    <div className="icon">
                                        <img src={importer.ic.require('NonSmart.svg')} alt="" />
                                    </div>
                                </div>
                            </Card>

                            <Card>
                                <div className='stats'>
                                    <div className='values'>
                                        <Title>Meter Tele-Gestiti</Title>
                                        <h3>{mapStats.NMeterTeleGestiti !== undefined ? mapStats.NMeterTeleGestiti.toLocaleString() : <Skeleton />}</h3>
                                    </div>

                                    <div className="icon">
                                        <img src={importer.ic.require('TeleGestiti.svg')} alt="" />
                                    </div>
                                </div>
                            </Card>

                            <Card>
                                <div className='stats'>
                                    <div className='values'>
                                        <Title>Meter Tele-Letti</Title>
                                        <h3>{mapStats.NMeterTeleLetti !== undefined ? mapStats.NMeterTeleLetti.toLocaleString() : <Skeleton />}</h3>
                                    </div>

                                    <div className="icon">
                                        <img src={importer.ic.require('TeleLetti.svg')} alt="" />
                                    </div>
                                </div>
                            </Card>

                            <Card>
                                <div className='stats'>
                                    <div className='values'>
                                        <Title>Non Tele-Letti</Title>
                                        <h3>{mapStats.nonRedMeters !== undefined ? mapStats.nonRedMeters.toLocaleString() : <Skeleton />}</h3>
                                    </div>

                                    <div className="icon">
                                        <img src={importer.ic.require('NonLetti.svg')} alt="" />
                                    </div>
                                </div>
                            </Card>
                        </div>
                    </Card>
                </main>

                <aside>
                    <Card >
                        <CustomTab
                            tabs={[{ title: lang.towns }, { title: lang.province }]}
                            onChange={t => setCurrTab(t)}
                        >
                            <Select
                                className='w-100'
                                size='small'
                                MenuProps={{ style: { maxHeight: "30vh" } }}
                                value={currTab === 0 ? selectedCity : selectedProvince}
                                onChange={(event) => currTab === 0
                                    ? setSelectedCity(event.target.value as number)
                                    : hideUnselectedProvinces(event.target.value as number)
                                }
                            >
                                {[
                                    {
                                        value: -1,
                                        label: lang.all
                                    },
                                    ...(currTab === 0
                                        ? cities.map<CustomSelectOption>(c => ({ value: c.id, label: c.name }))
                                        : provinces
                                    )
                                ].map(o => <MenuItem value={o.value}>{o.label}</MenuItem>)}
                            </Select>
                        </CustomTab>
                    </Card>

                    <CustomButton onClick={() => setShowExportModal(true)}>Esporta KPI</CustomButton>

                    {showExportModal &&
                        <CustomModal
                            title='Esporta KPI'
                            titlePosition='center'
                            closeButton
                            closeOnOverlayClick
                            onClose={() => setShowExportModal(false)}
                            contentStyle={{ width: "70vw" }}
                            isOpen={showExportModal}
                        >
                            <div style={{ display: "flex", gap: "10px", marginBottom: "10px" }}>
                                <CustomSelect
                                    style={{ width: "250px" }}
                                    label="KPI"
                                    value={exportKpi}
                                    onChange={value => {
                                        setKpiExportPreview([]);
                                        setExportKpi(value as "managed" | "read");
                                    }}
                                    options={[
                                        { value: "managed", label: "Perc. tele-gestiti su meters" },
                                        { value: "read", label: "Perc. tele-letti su meters" }
                                    ]}
                                />
                                <CustomInput
                                    label="Anno"
                                    style={{ width: 100 }}
                                    type='number'
                                    min={2024}
                                    max={new Date().getFullYear()}
                                    value={exportYear}
                                    onChange={value => {
                                        setKpiExportPreview([]);
                                        setExportYear(value as number);
                                    }}
                                />

                                <CustomButton
                                    disabled={loadingExport || Boolean(kpiExportPreview.length)}
                                    variant={loadingExport || kpiExportPreview.length ? "light" : 'secondary'}
                                    style={{ width: "20%" }}
                                    nospam
                                    onClick={getKpiExportAPI}
                                >
                                    {loadingExport && <CircularProgress />}
                                    Anteprima
                                </CustomButton>
                                <CustomButton
                                    disabled={loadingExport}
                                    variant={loadingExport ? "light" : undefined}
                                    style={{ width: "20%" }}
                                    nospam
                                    onClick={downloadExportExcel}
                                >
                                    {loadingExport && <CircularProgress />}
                                    Scarica
                                </CustomButton>
                            </div>

                            <div style={{ height: "50vh" }}>
                                <CustomTable
                                    hidePerPage
                                    columns={[
                                        { name: "week", displayName: "Settimana N." },
                                        ...cities.map(c => ({ name: c.name, width: 180 }))
                                    ]}
                                    rows={kpiExportPreview}
                                    loading={loadingExport}
                                />
                            </div>
                        </CustomModal>
                    }

                    <Card>
                        <div className='section smartMeter pb-0'>
                            <div>
                                <div>
                                    <Title>Tele-Letti</Title>
                                    <ValueOnMax max={mapStats.smartMeters || 0} value={mapStats.NMeterTeleLetti || 0} />
                                </div>

                                <div><RadialChart max={mapStats.smartMeters || 100} value={mapStats.NMeterTeleLetti || 0} /></div>
                            </div>
                        </div>
                    </Card>

                    <Card>
                        <div className='section smartMeter pb-0'>
                            <div>
                                <div>
                                    <Title>Tele-Gestiti</Title>
                                    <ValueOnMax max={mapStats.smartMeters || 0} value={mapStats.NMeterTeleGestiti || 0} />
                                </div>

                                <div><RadialChart max={mapStats.smartMeters || 100} value={mapStats.NMeterTeleGestiti || 0} /></div>
                            </div>
                        </div>
                    </Card>

                    <Card id='summary'>
                        <div className='section smartMeter'>
                            <div>
                                <div>
                                    <Title>{lang.smart_meter}</Title>
                                    <ValueOnMax max={mapStats.endUsers || 0} value={mapStats.smartMeters || 0} />
                                </div>

                                <div><RadialChart max={mapStats.endUsers || 100} value={mapStats.smartMeters || 0} color="#95D5F6" /></div>
                            </div>
                        </div>
                        <div className='section'>
                            <Title>{lang.brand}</Title>

                            {mapStats.brands.length
                                ?
                                mapStats.brands.map((a, i) =>
                                    <RowStat
                                        key={i}
                                        label={a.type}
                                        value={a.count.toLocaleString()}
                                        perc={a.perc?.toLocaleString()}
                                    />)
                                :
                                <><Skeleton /><Skeleton /><Skeleton /></>
                            }
                        </div>
                    </Card>

                    <Card id="SFchart">
                        <Title>{lang.SF_distribution}</Title>

                        <GestBarChart
                            x="x"
                            hideY
                            bars={[{ y: "y", color: "#95D5F6", label: "Valore" }]}
                            dataSource={Object
                                .keys(defaultChart)
                                .map<ITimeSeries>(k => ({ x: k, y: (SFChart as any)[k] }))
                            }
                        />
                    </Card>
                </aside>
            </div>
        </Fade>
    );
};
export default React.memo(Dashboard);