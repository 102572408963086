import './Navbar.scss';

import React, { useCallback, useContext, useState } from "react";
import { useNavigate } from 'react-router-dom';


/*  COMPONENTS  */
import {
    ContextMenuContent,
    CustomButton,
    CustomIconButton,
    CustomModal,
    Popover,
    Title,
} from '../../../gest';


/*  ICONS    */
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import PublicRoundedIcon from '@mui/icons-material/PublicRounded';


import HelpOutlineRoundedIcon from '@mui/icons-material/HelpOutlineRounded';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';

import Avatar from '@mui/material/Avatar';
import Tooltip from '@mui/material/Tooltip';


import { getBrowserLanguage, stringAvatar } from '../../../../core/helpers/tools';

import { ctxSession, ctxSettings } from '../../../../core/store';


import config from '../../../../core/config';
import Axios from '../../../../core/helpers/axios';
import importer from '../../../../core/helpers/importer';

import language from '../../../../core/languages';

const Navbar = (props: any/*props: HeaderProps*/) => {
    const navigate = useNavigate();

    const session = useContext(ctxSession);

    const isOpened = props.isOpened;

    const settings = useContext(ctxSettings);
    const lang = language(settings?.data?.lang);


    const [infoOpened, setInfoOpened] = React.useState(false);
    const anchorRef = React.useRef<HTMLButtonElement>(null);


    const handleToggle = () => {
        setInfoOpened((prevOpen) => !prevOpen);
    };
    const handleClose = (event: Event | React.SyntheticEvent) => {
        if (
            anchorRef.current &&
            anchorRef.current.contains(event.target as HTMLElement)
        ) {
            return;
        }

        setInfoOpened(false);
    };


    const [isLoggingOut, setIsLoggingOut] = useState<boolean>(false);


    const logout = () => {
        const onSuccess = () => {
            console.log('Navbar logout');
            if (session && session.set) {
                localStorage.clear();
                session.set(null);
            }
        };
        const onError = () => { };

        Axios(session, 'get', `${config.api.internal}/Auth/logout`, onSuccess, onError);
    };


    const updateLanguage = useCallback((lang: "it" | "en" | "fr") => {
        const formData = {
            lang
        };

        const onSuccess = () => {
            let settingsLS = JSON.parse(localStorage.getItem('settings') || '{}');
            if (settings && settings.set) {
                settingsLS.lang = formData.lang;
                settings.set(settingsLS);
                localStorage.setItem('settings', JSON.stringify(settingsLS));
                console.log(settings);
            }
        };
        const onError = () => { };


        Axios(session, 'patch', `${config.api.internal}/Account/lang`, onSuccess, onError, formData);
    }

        // eslint-disable-next-line react-hooks/exhaustive-deps
        , [settings, settings?.data, settings?.set]);


    const support = localStorage.getItem('support') === '1';

    return (
        <nav id="gestNavbar" className={isOpened ? 'opened' : ''}>
            <div className="logoBox"
                onClick={() => { navigate('/'); }}
                onAuxClick={(e: any) => {
                    if (e.button === 1) {
                        window.open('/', '_blank');
                    }
                }
                }
            >
                <img id="logo"
                    src={importer.img.require("webGestLogo.svg")}
                    alt="webGestLogo"
                />
            </div>

            <div className="utils">
                <div style={{ width: '100%' }}>
                    <IconButton
                        className={'d-md-none'}
                        ref={anchorRef}
                        id="composition-button"
                        aria-controls={infoOpened ? 'composition-menu' : undefined}
                        aria-expanded={infoOpened ? 'true' : undefined}
                        aria-haspopup="true"
                        onClick={handleToggle}
                        sx={{
                            p: '8px', '& img': {
                                width: 18, height: 18
                            }
                        }}
                    >
                        <img src={importer.ic.require('info.svg')} alt="" />
                    </IconButton>
                    {
                        support && (session?.data?.userData?.email || session?.data?.userData?.role === 'admin')
                            ?
                            <Tooltip title="Support">
                                <IconButton
                                    sx={{
                                        p: '8px', '& img': {
                                            width: 22, height: 22
                                        }
                                    }}
                                >
                                    <a href={config.platforms.support} target="_blank" rel="noreferrer">
                                        {/* <img src={importer.ic.require('supportBlue.svg')} alt="" /> */}
                                        <HelpOutlineRoundedIcon className='text-primary' />
                                    </a>
                                </IconButton>
                            </Tooltip>
                            :
                            <></>
                    }
                    <Popper
                        open={infoOpened}
                        anchorEl={anchorRef.current}
                        role={undefined}
                        placement="bottom-start"
                        transition
                        disablePortal
                    >
                        {({ TransitionProps, placement }) => (
                            <Grow
                                {...TransitionProps}
                                style={{
                                    transformOrigin: 'right top',
                                }}
                            >
                                <Paper>
                                    <ClickAwayListener onClickAway={handleClose}>
                                        <></>
                                    </ClickAwayListener>
                                </Paper>
                            </Grow>
                        )}
                    </Popper>


                </div>
                <Popover
                    variant="ghost"
                    anchor="bottom-right"
                    arrow={true}
                    overflow={true}
                    placeholder={
                        <div className='account'>
                            <Avatar {...stringAvatar(session?.data?.userData?.fullname)} />
                            <div className="anag">
                                <h4>{`${session?.data?.userData?.firstname} ${session?.data?.userData?.lastname}`}</h4>
                                <h5>@{session?.data?.userData?.username}</h5>
                            </div>
                        </div>
                    }
                >
                    <ContextMenuContent
                        anchor="right"
                        options={[
                            {
                                leftIcon: <PublicRoundedIcon style={{ fill: "var(--primary)" }} />,
                                label: lang.language,
                                subs: [{
                                    leftIcon: <img style={{}} src={importer.flag.require('it.svg')} alt="" />,
                                    label: lang.universal.languages.italian,
                                    rightIcon: settings?.data?.lang === 'it' || (!settings?.data?.lang && getBrowserLanguage() === 'it') ? <CheckRoundedIcon /> : <></>,
                                    onClick: () => {
                                        updateLanguage('it');
                                    }
                                },
                                {
                                    leftIcon: <img style={{}} src={importer.flag.require('gb.svg')} alt="" />,
                                    label: lang.universal.languages.english,
                                    rightIcon: settings?.data?.lang === 'en' || (!settings?.data?.lang && getBrowserLanguage() === 'en') ? <CheckRoundedIcon /> : <></>,
                                    onClick: () => {
                                        updateLanguage('en');
                                    }
                                },
                                {
                                    leftIcon: <img style={{}} src={importer.flag.require('fr.svg')} alt="" />,
                                    label: lang.universal.languages.french,
                                    rightIcon: settings?.data?.lang === 'fr' || (!settings?.data?.lang && getBrowserLanguage() === 'fr') ? <CheckRoundedIcon /> : <></>,
                                    onClick: () => {
                                        updateLanguage('fr');
                                    }
                                }
                                ]
                            }
                        ]}
                    />
                </Popover>


                <CustomIconButton
                    img={importer.ic.require('logoutBlue.svg')}
                    onClick={() => {
                        setIsLoggingOut(true);
                    }}
                />
            </div>


            <CustomModal
                isOpen={isLoggingOut}

                onClose={() => {
                    setIsLoggingOut(false);
                }}
                closeButton={true}
                footer={<>
                    <CustomButton
                        variant="secondary"
                        onClick={() => {
                            setIsLoggingOut(false);
                        }}
                    >
                        {lang.cancel}
                    </CustomButton>
                    <CustomButton
                        onClick={logout}
                    >
                        {lang.logout}
                    </CustomButton>
                </>}
            >
                <img height="100" width="100" style={{ margin: 'auto' }} src={importer.img.require('exit.png')} alt="" />
                <Title class="m-2">{lang.really_want_logout}</Title>

            </CustomModal>
        </nav>
    );
};
export default React.memo(Navbar);