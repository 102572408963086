import React from "react";
import { useLocation, useNavigate } from 'react-router-dom';
import config from '../../../../core/config';
import Axios from '../../../../core/helpers/axios';
import importer from '../../../../core/helpers/importer';
import { stringAvatar } from '../../../../core/helpers/tools';
import language from '../../../../core/languages';
import { MenuAreaModel, MenuOptionModel } from '../../../../core/models';
import './Sidebar.scss';
//#region COMPONENTS
import Avatar from '@mui/material/Avatar';
import Slide from '@mui/material/Slide';
import Tooltip from '@mui/material/Tooltip';
import {
    CustomButton,
    CustomModal,
    Title,
} from '../../../gest';
//#endregion
//#region ICONS
import ArrowDropDownRoundedIcon from '@mui/icons-material/ArrowDropDownRounded';
import HelpOutlineRoundedIcon from '@mui/icons-material/HelpOutlineRounded';
//#endregion
//CONTEXTS
import { ctxSession, ctxSettings } from '../../../../core/store';

const Sidebar = (props: any) => {
    const navigate = useNavigate();
    const location = useLocation();

    const session = React.useContext(ctxSession);
    const settings = React.useContext(ctxSettings);

    const lang = language(settings?.data?.lang);
    const support = localStorage.getItem('support') === '1';
    const { isOpened, setIsOpened } = props;

    //#region STATES
    //MENU
    const [menu, setMenu] = React.useState<MenuAreaModel[]>([]);
    const [menuWindow, setMenuWindow] = React.useState<any>({ isOpened: false, top: 0 });
    const [subMenu, setSubMenu] = React.useState<MenuOptionModel[]>([]);
    const [windowSubMenu, setWindowSubMenu] = React.useState<any>({ label: '', subs: [] });
    const [selectedArea, setSelectedArea] = React.useState<MenuAreaModel>();

    const menuRef = React.useRef<HTMLDivElement>(null);
    const refSidebar = React.useRef<HTMLElement>(null);
    const refSidebarMenu = React.useRef<HTMLDivElement>(null);
    const topSideRef = React.useRef<HTMLDivElement>(null);
    const topMenuRef = React.useRef<HTMLDivElement>(null);
    const bottomSideRef = React.useRef<HTMLDivElement>(null);
    const bottomMenuRef = React.useRef<HTMLDivElement>(null);
    //FLAGS
    const [showScrollArrow, setShowScrollArrow] = React.useState<boolean>(false);
    const [isLoggingOut, setIsLoggingOut] = React.useState<boolean>(false);
    //#endregion

    //#region METHODS
    const logout = () => {
        const onSuccess = () => {
            if (session && session.set) {
                localStorage.clear();
                session.set(null);
            }
        };
        const onError = () => { };

        Axios(session, 'get', `${config.api.internal}/Auth/logout`, onSuccess, onError);
    };

    const printMenuItem = (area: any, isHomeButton: boolean = false) =>
        <div key={area.id} className={`item ${selectedArea?.id === area.id ? 'selected' : ''} ${isHomeButton ? ' home' : ''}`}
            onAuxClick={(e) => {
                if (area.subs.length === 1) {
                    const option = area.subs[0];
                    // Apri in una nuova scheda solo se è stato cliccato il tasto della rotellina
                    if (option.enabled && option.path && e.button === 1) window.open(option.path, '_blank');
                }
            }}
            onClick={(e: any) => {
                if (area.subs.length === 1) {
                    const option = area.subs[0];
                    if (option.enabled && option.path) {

                        navigate(option.path);
                        setSelectedArea(area);
                        setSubMenu(area.subs);

                        setIsOpened(false);
                    }
                }
                if (isOpened) {   // setta il submenu solo se aperto
                    setSubMenu(area.subs);
                    setSelectedArea(area);
                }

            }}
            onMouseEnter={(e) => {
                if (!isOpened && area.subs.length > 1) {  // popola il submenu della window
                    setWindowSubMenu({
                        label: area.label,
                        subs: area.subs
                    });
                    openMenuWindow(e);
                }
            }}
            onMouseLeave={(e) => {
                closeMenuWindow();
            }}
        >
            <img className="protect" src={importer.ic.require(area.icon)} alt="" />
        </div>;

    const toggleMenu = () => setIsOpened(!isOpened);

    const openMenuWindow = (e: any) => setMenuWindow({ isOpened: true, top: e.target.getBoundingClientRect().y });

    const closeMenuWindow = () => setMenuWindow({ isOpened: false, top: 0 });
    //#endregion

    //#region STATE CHANGE
    React.useEffect(() => {
        const menu = localStorage.getItem('menu');
        if (menu) {
            setMenu(JSON.parse(menu));
        } else {
            const onSuccess = (response: any) => {
                const menu = response.data.data;
                localStorage.setItem('menu', JSON.stringify(menu));
                setMenu(menu);
            };
            const onError = () => {
            };

            Axios(session, 'get', `${config.api.internal}/Settings/menu`, onSuccess, onError);
        }


        const settingsLS = localStorage.getItem('settings');
        if (settingsLS) {
            if (settings && settings.set) {
                settings.set(JSON.parse(settingsLS));
            }
        } else {
            const onSuccess = (response: any) => {
                const settingsRES = response.data.data;
                localStorage.setItem('settings', JSON.stringify(settingsRES));

                if (settings && settings.set) {
                    settings.set(settingsRES);
                }

            };
            const onError = () => {
            };

            Axios(session, 'get', `${config.api.internal}/Settings`, onSuccess, onError);
        }
    }, []);

    React.useEffect(() => {
        if (menu.length) {
            for (const area of menu) {
                for (const option of area.subs) {
                    if (location.pathname === option.path) {
                        setSelectedArea(area);
                        setSubMenu(area.subs);
                        break;
                    }
                }
            }
        }
    }, [menu, isOpened]);

    React.useEffect(() => {
        const showArrow = () => {
            if (menuRef.current) {
                // console.log(menuRef.current.scrollTop, menuRef.current.scrollHeight, menuRef.current.clientHeight)
                if (menuRef.current.clientHeight < menuRef.current.scrollHeight
                    &&
                    (menuRef.current.scrollTop + menuRef.current.clientHeight) < menuRef.current.scrollHeight
                ) {
                    setShowScrollArrow(true);
                } else {
                    setShowScrollArrow(false);
                }
            }
        };

        const observer = new ResizeObserver((entries) => {
            for (let entry of entries) {
                if (entry.target === menuRef.current) {
                    showArrow();
                }
            }
        });

        if (menuRef.current) {   // Collega l'osservatore alla tabella
            observer.observe(menuRef.current);
            menuRef.current.addEventListener('scroll', showArrow);
        }

        return () => {
            if (menuRef.current) {
                observer.unobserve(menuRef.current);
                menuRef.current.removeEventListener('scroll', showArrow);
            }
        };
    }, []);

    React.useEffect(() => {
        if (isOpened) {
            // Funzione di gestione del clic al di fuori del div
            const handleClickOutside = (event: any) => {
                // console.log(event)
                if (refSidebar.current && !refSidebar.current.contains(event.target) && refSidebarMenu.current && !refSidebarMenu.current.contains(event.target)) {
                    // Clic al di fuori del div, eseguire l'azione desiderata qui
                    setIsOpened(false);
                }
            };

            // Aggiunge un event listener al documento per gestire i clic
            document.addEventListener('click', handleClickOutside);

            // Pulisce l'event listener quando il componente si smonta
            return () => {
                document.removeEventListener('click', handleClickOutside);
            };
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isOpened]);
    //#endregion

    return (
        <>
            <Slide direction="right" in={isOpened}>
                <div ref={refSidebarMenu} id="menu" className={"menu " + (isOpened ? 'opened' : '')}>
                    <div className="pageTitle">
                        <h4>{selectedArea?.label ? lang[selectedArea?.label] : selectedArea?.label}</h4>
                    </div>
                    <div className='menuItems'>
                        <div className="items">
                            {subMenu.length > 0 && subMenu.map((option: any, k: number) =>
                                <a key={k}
                                    href={option.path}
                                    className={`item ${option.enabled ? '' : 'disabled'} ${location.pathname === option.path ? 'selected' : ''}`}
                                    onAuxClick={(e: any) => {
                                        // Apri in una nuova scheda solo se è stato cliccato il tasto della rotellina
                                        if (option.enabled && option.path && e.button === 1) window.open(option.path, '_blank');
                                    }}
                                    onClick={(e: any) => {
                                        e.preventDefault();
                                        if (option.enabled && option.path) {
                                            setIsOpened(false);
                                            navigate(option.path);
                                        }
                                    }}
                                >
                                    <img className="protect" src={importer.ic.require(option.icon)} alt="" />
                                    <h5>{lang[option.label] ?? option.label}</h5>
                                </a>
                            )

                            }
                        </div>
                    </div>

                    <div className='profile'>
                        <div>
                            <h4>{`${session?.data?.userData?.firstname} ${session?.data?.userData?.lastname}`}</h4>
                            <h5>@{session?.data?.userData?.username}</h5>
                        </div>
                        <div className="item"
                            onClick={() => {
                                setIsLoggingOut(true);
                            }}
                        >
                            <img className="protect" src={importer.ic.require('logoutWhite.svg')} alt="" />
                        </div>

                    </div>
                </div>
            </Slide>



            <aside id="gestSidebar" ref={refSidebar}>
                {menuWindow.isOpened &&
                    <div id="menuWindow" style={{ top: menuWindow.top }} onMouseEnter={() => setMenuWindow({ isOpened: true, top: menuWindow.top })} onMouseLeave={() => { closeMenuWindow(); }}>
                        <div className='window'>
                            <div className="pageTitle">
                                <h4>{lang[windowSubMenu.label]}</h4>
                            </div>
                            {windowSubMenu.subs.length && windowSubMenu.subs.map((option: any, k: number) =>
                                <a key={k}
                                    href={option.path}
                                    className={`item ${option.enabled ? '' : 'disabled'} ${location.pathname === option.path ? 'selected' : ''}`}
                                    onAuxClick={(e: any) => {
                                        // Apri in una nuova scheda solo se è stato cliccato il tasto della rotellina
                                        if (option.enabled && option.path && e.button === 1) window.open(option.path, '_blank');
                                    }}
                                    onClick={(e: any) => {
                                        e.preventDefault();
                                        if (option.enabled && option.path) {
                                            const area = menu[(menu.findIndex((v: any) => v.id === option.areaID))];
                                            setSelectedArea(area);
                                            setSubMenu(area.subs);

                                            navigate(option.path);
                                            // console.log(option.path)
                                        }
                                    }}
                                >
                                    <img className="protect" src={importer.ic.require(option.icon)} alt="" />
                                    <h5>{lang[option.label] ?? option.label}</h5>
                                </a>
                            )
                            }
                        </div>
                    </div>
                }

                <div ref={topSideRef} className='topSide'>
                    <div ref={topMenuRef} className="topMenu">
                        <div className="item"
                            onClick={() => { toggleMenu(); }}
                        >
                            {isOpened ? <img src={importer.ic.require('menuClose.svg')} alt="" /> : <img src={importer.ic.require('menuOpen.svg')} alt="" />}
                        </div>
                        {menu.length > 0 &&
                            printMenuItem(menu[0], true)
                        }
                    </div>
                    <div ref={menuRef} className="menu">
                        {menu.length > 0 &&
                            menu.map((area: any, k: number) => {
                                if (!k) return null;
                                return printMenuItem(area);
                            })
                        }
                    </div>

                    {showScrollArrow === true &&
                        <div className='scrollBelow'>
                            <ArrowDropDownRoundedIcon />
                        </div>
                    }
                </div>

                <div ref={bottomSideRef} className='bottomSide'>
                    <div ref={bottomMenuRef} className="bottomMenu">
                        {support && (session?.data?.userData?.email || session?.data?.userData?.role === 'admin') &&
                            <Tooltip title="Support" placement="right">
                                <div className="item" onClick={() => { window.open(config.platforms.support, '_blank'); }}>
                                    {/* <img className="protect" src={importer.ic.require('support.svg')} alt="" /> */}
                                    <HelpOutlineRoundedIcon className='text-white' />
                                </div>
                            </Tooltip>
                        }
                        {!isOpened &&
                            <div className="item"
                                onClick={() => {
                                    setIsLoggingOut(true);
                                }}
                            >
                                <img className="protect" src={importer.ic.require('logoutWhite.svg')} alt="" />
                            </div>
                        }
                    </div>

                    <div className="account">
                        <Avatar {...stringAvatar(session?.data?.userData?.fullname)} />
                    </div>
                </div>

                <CustomModal
                    isOpen={isLoggingOut}

                    onClose={() => {
                        setIsLoggingOut(false);
                    }}
                    closeButton={true}
                    footer={<>
                        <CustomButton
                            variant="secondary"
                            onClick={() => {
                                setIsLoggingOut(false);
                            }}
                        >
                            {lang.cancel}
                        </CustomButton>
                        <CustomButton
                            onClick={logout}
                        >
                            {lang.logout}
                        </CustomButton>
                    </>}
                >
                    <img height="100" width="100" style={{ margin: 'auto' }} src={importer.img.require('exit.png')} alt="" />
                    <Title class="m-2">{lang.really_want_logout}</Title>

                </CustomModal>
            </aside>

        </>
    );
};
export default Sidebar;