import './Popover.scss'; // import './css/Popover.scss'

import React, { useEffect, useRef, useState } from "react";

import Title from '../Title/Title';

import importer from '../../../core/helpers/importer';


const Popover: React.FC<any> = (props: any) => {
    const [isOpen, setIsOpen] = useState(false);

    const togglePopover = () => {
        setIsOpen(!isOpen);
    };


    let childrens = null;
    if (props.children && Array.isArray(props.children)) {
        childrens = props.children.map((component: any, i: number) => component);
    } else {
        childrens = props.children;
    }

    const ref = useRef<any>();


    useEffect(() => {
        const handleOutsideClick = (event: MouseEvent) => {
            if (ref.current && !ref.current.contains(event.target)) {
                setIsOpen(false);
            }
        };

        document.addEventListener('click', handleOutsideClick);

        return () => {
            document.removeEventListener('click', handleOutsideClick);
        };
    }, []);

    let classes = 'std';
    switch (props.variant) {
        case 'ghost': {
            classes = 'ghost';
            break;
        }
        default:
        case 'std': {
            break;
        }
    }

    switch (props.anchor) {
        case 'right': {
            classes += ' anchorRight';
            break;
        }
        case 'left': {
            classes += ' anchoLeft';
            break;
        }
        case 'bottom-right': {
            classes += ' anchorBottomRight';
            break;
        }
        case 'bottom-center': {
            classes += ' anchorBottomCenter';
            break;
        }
        default:
        case 'bottom-left':
        case 'std': {
            classes += ' anchorBottomLeft';
            break;
        }
    }



    // if(props.arrow) {
    //     classes += ' arrow';
    // }

    if (props.class) {
        classes += ' ' + props.class;
    }

    return (
        <div
            ref={ref}
            className={`popover-container ${props.overflow ? 'overflowable' : ''} ${classes}`}
        >
            <div className="popover-trigger" onClick={togglePopover}>
                <span>
                    {props.placeholder}
                </span>
                {
                    props.arrow
                    &&
                    (
                        isOpen
                            ?
                            <img className='arrow' src={importer.ic.require('chevronTop.svg')} alt="" />
                            :
                            <img className='arrow' src={importer.ic.require('chevronBottom.svg')} alt="" />
                    )
                }
            </div>
            {isOpen && (
                <div className="popover-content">
                    {
                        props.title
                        &&
                        <div className='popover-title'>
                            <Title>{props.title}</Title>
                        </div>
                    }
                    {props.options && props.options.length ?
                        <div className="popover-options">
                            {
                                props.options.map((v: any, k: number) =>
                                    <div
                                        key={k}
                                        className='popover-option'
                                    >
                                        <span>{v.label || v.name || ''}</span>
                                        <div className="action">
                                            {v.action}
                                        </div>
                                    </div>
                                )
                            }
                        </div>
                        :
                        <></>
                    }
                    <div className="popover-body">
                        {
                            childrens
                        }
                    </div>
                </div>
            )}
        </div>

    );
};
export default React.memo(Popover);