import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import './Site.scss';
//#region  COMPONENTS
import { CustomSnackbarContainer } from '../../gest';
import GetOld from '../_SHARED/GetOld';
import Navbar from '../_SHARED/Navbar/Navbar';
import Sidebar from '../_SHARED/Sidebar/Sidebar';
import Dashboard from '../Home/Dashboard';
//#endregion
//#region MODELS
import { SettingsModel } from '../../../core/models';
//#endregion
//CONTEXTS
import { ctxSettings } from '../../../core/store';

const Site = () => {
    const [settings, setSettings] = React.useState<SettingsModel | null>(null);
    const [isOpened, setIsOpened] = React.useState<boolean>(false);   // sidebar

    return (
        <div id="Site">
            <ctxSettings.Provider value={{ data: settings, set: setSettings }} >
                <CustomSnackbarContainer>
                    <Sidebar isOpened={isOpened} setIsOpened={setIsOpened} />

                    <div id="content">
                        <Navbar isOpened={isOpened} />

                        <div className="pages">
                            <Routes>
                                <Route path="/" element={<Dashboard />} />

                                {/* NETWORK ANALYSIS */}
                                <Route path="/networkAnalysis" element={<GetOld />} />

                                {/* MONITOR */}
                                <Route path="/consumptionMonitor" element={<GetOld />} />
                                <Route
                                    path="*"
                                    element={<Navigate to="/" />}
                                />
                            </Routes>
                        </div>

                    </div>

                </CustomSnackbarContainer>
            </ctxSettings.Provider>
        </div>
    );
};
export default React.memo(Site);