import React from 'react';
import './animations.scss';
import './App.scss';
//#region COMPONENTS
import SplashScreen from './custom/_SHARED/SplashScreen/SplashScreen';
import Auth from './custom/Auth/Auth';
import Site from './custom/Site/Site';
//#endregion
//#region MODELS
import { SessionModel } from '../core/models';
//#endregion
//CONTEXTS
import { ctxSession } from '../core/store';

const App = () => {
    const [session, setSession] = React.useState<SessionModel | null>({
        userData: null,
        support: false
    });

    const main = React.useMemo(() => {
        if (session && !session.userData) {
            return <SplashScreen />;
        }
        else if (session && session.userData) { //&& session.userData
            return <Site />;
        } else {
            return <Auth />;
        }
    }, [session]);

    return (
        <ctxSession.Provider value={{ data: session, set: setSession }} >
            {main}
        </ctxSession.Provider>
    );
};

export default App;
