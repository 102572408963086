const dev = {
    app: {
        name: 'AQP-Panel Local'
    },
    api: {
        internal: `${window.location.origin.replace(window.location.protocol, 'https:').replace(window.location.port, '7045')}`,
        old: `${window.location.origin}/old`
    },
    platforms: {
        support: 'http://localhost/support'
    },
    env: {
        map: {
            defaultCenter: { lat: 37.583658, lng: 15.103183 }   //sede di GEST
        }
    }
};


const prod = {
    app: {
        name: 'AQP-Panel'
    },
    api: {
        internal: `${window.location.origin}/${process.env.REACT_APP_API_URL}`,
        old: `${window.location.origin}/${process.env.REACT_APP_OLD}`
    },
    platforms: {
        support: 'https://support.gest.cloud'
    },
    env: {
        map: {
            defaultCenter: { lat: 37.583658, lng: 15.103183 }   //sede di GEST
        }
    }
};


const config = process.env.NODE_ENV === "development" ? dev : prod;

export default config;