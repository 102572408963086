import React from 'react';
//MODELS
import { TooltipProps } from './TooltipProps';


const GestTooltip = ({ payloadProp, payload, label, }: TooltipProps) =>//needs recharts payload to work (placed in recharts tooltip component)
	<div id="gestTooltip" className="card" style={{ backgroundColor: "white" }}>
		<label><strong>{label}</strong></label>

		{payloadProp.map((pp, i) =>
			<div key={`tooltipField${i}`} style={{ color: pp.spanColor }}>
				{pp.label} : &nbsp;
				<strong>{payload && payload[0] && (payload[0].payload[pp.prop] || payload[0].payload[pp.prop] === 0) ? payload[0].payload[pp.prop] : "No data"}</strong>
			</div>)}
	</div>;
export default React.memo(GestTooltip);