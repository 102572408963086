import React from 'react';
import { useNavigate } from 'react-router-dom';
import config from '../../../../core/config';
import Axios from '../../../../core/helpers/axios';
import formDataValidator from '../../../../core/helpers/formData';
import importer from '../../../../core/helpers/importer';
import language from '../../../../core/languages';
import '../Login/LogIn.scss';
//#region COMPONENTS 
import Alert from '@mui/material/Alert';
import {
    Card,
    CustomButton,
    CustomInput,
} from '../../../gest';
//#endregion
//#region ICONS
import EmailRoundedIcon from '@mui/icons-material/EmailRounded';
//#endregion
//CONTEXTS
import { ctxSession } from '../../../../core/store';


const ForgotPassword = () => {

    const navigate = useNavigate();
    const session = React.useContext(ctxSession);

    const lang = language();

    const [loading, setLoading] = React.useState<boolean>(false);
    const [errorAnimation, setErrorAnimation] = React.useState<boolean | string>(false);
    const [error, setError] = React.useState<boolean | string>(false);

    const animateError = () => {
        setErrorAnimation(true);
        setTimeout(() => {
            setErrorAnimation(false);
        }, 100);
    };


    const [submitOK, setSubmitOK] = React.useState<boolean>(false);
    const username = React.useRef<string | null>(null);

    const onSubmit = (e: any) => {

        e.preventDefault();

        const formData: any = formDataValidator(e.target, 'object');

        if (formData.username) {
            username.current = formData.username;
            setLoading(true);
            setError(false);

            const onSuccess = (response: any) => {
                setLoading(false);
                setSubmitOK(true);
            };

            const onError = (response: any) => {
                animateError();
                setLoading(false);

                username.current = null;
            };

            Axios(session, 'post', `${config.api.internal}/Auth/resetPassword`, onSuccess, onError, formData);
        }
        else {
            animateError();
        }

    };

    // const timer = React.useRef<any>();
    const [sendAgainTimer, setSendAgainTimer] = React.useState<number>(61);

    React.useEffect(() => {
        if (submitOK && sendAgainTimer > 0) {
            setTimeout(() => {
                setSendAgainTimer(sendAgainTimer - 1);
            }, 1000);
        }
    }, [submitOK, sendAgainTimer]);


    const sendAgain = React.useCallback(() => {
        if (submitOK && username.current) {


            const onSuccess = (response: any) => {
                setLoading(false);
                setSubmitOK(true);
            };

            const onError = (response: any) => {
                animateError();
                setLoading(false);

                username.current = null;
            };

            Axios(session, 'post', `${config.api.internal}/Auth/resetPassword`, onSuccess, onError, { username: username.current });
        }
    }, [submitOK, username.current]);


    return (
        <Card type="floating" id="Login" style={{ width: '350px' }}>
            <div className="logo">
                <img className={`mb-3 ${loading ? 'spin' : ''} ${errorAnimation ? 'shake' : ''} `} src={importer.img.require('Logo.svg')} alt="" />
                <h4 className='mb-0'>{lang.reset_password}</h4>
            </div>
            <div className='my-4'>
                {
                    submitOK
                        ?
                        <>
                            <Alert icon={false}>

                                <p className='m-0 mb-1 text-justify'>
                                    {lang.password_request_completed}
                                </p>
                                <p className='m-0 text-justify'>
                                    {lang.check_your_inbox}
                                </p>
                                <div className='mt-2 w-100 justify-content-center'>
                                    <EmailRoundedIcon fontSize='large' />
                                </div>

                                <span className='d-flex text-xs'>
                                    {lang.didnt_receive_email}
                                    &nbsp;
                                    {
                                        <a
                                            onClick={() => {
                                                if (!sendAgainTimer) {
                                                    sendAgain();
                                                    setSendAgainTimer(61);
                                                }
                                            }}

                                            className={sendAgainTimer ? 'text-disabled' : ''}
                                        >
                                            {lang.send_again}
                                        </a>
                                    }
                                    &nbsp;
                                    {sendAgainTimer ? `(${sendAgainTimer})` : ''}
                                </span>

                            </Alert>

                            <a className='mt-2' onClick={() => { navigate('/login'); }}><strong>{lang.back_to_login}</strong></a>

                        </>
                        :
                        <form onSubmit={onSubmit}>
                            <fieldset>
                                <CustomInput name="username" variant="cloud" img={importer.ic.require('user.svg')} placeholder={lang.username} class="mb-2"></CustomInput>
                            </fieldset>
                            <div>
                                <CustomButton variant="primary" type={"submit"} class="w-100 mb-2">{lang.reset}</CustomButton>
                                <a className='' onClick={() => { navigate('/login'); }}><strong>{lang.back_to_login}</strong></a>
                            </div>
                        </form>
                }


            </div>
        </Card>
    );
};
export default React.memo(ForgotPassword);


// http://localhost:3001/