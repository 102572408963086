import React from 'react';
import Datetime from '../../../core/helpers/datetime';
import importer from '../../../core/helpers/importer';
import './Auth.scss';
//#region COMPONENTS
import { Navigate, Route, Routes } from 'react-router-dom';
import ForgotPassword from './ForgotPassword/ForgotPassword';
import LogIn from './Login/LogIn';
import ResetPassword from './ResetPassword/ResetPassword';
//#endregion
//MODELS

const Auth = () => {
    const getCurrentSeason = () => {
        const winter = "1222", spring = "0321", summer = "0621", autumn = "0923";
        const today = new Datetime();
        const dayClass = today.getMonth() + today.getDay(); //"1101"

        let authClass = "default";

        if (dayClass >= winter || dayClass < spring) {
            authClass = "winter";
        }
        else if (dayClass >= spring && dayClass < summer) {
            authClass = "spring";
        }
        else if (dayClass >= summer && dayClass < autumn) {
            authClass = "summer";
        } else if (dayClass >= autumn && dayClass < winter) {
            authClass = "autumn";
        }

        return authClass;
    };

    return (
        <div id="Auth" className={getCurrentSeason()} >
            <div className={"container"}>
                <div className="mainLogo mb-4">
                    <img src={importer.img.require('fullLogo.svg')} alt="WebGEST SRL" />
                </div>

                <div className='pages'>
                    <Routes>
                        <Route path="/" element={<LogIn /*login = {props.login}*/ />} />
                        <Route path="/forgotPassword" element={<ForgotPassword />} />
                        <Route path="/resetPassword/:otp" element={<ResetPassword />} />
                        <Route
                            path="*"
                            element={<Navigate to="/" />}
                        />
                    </Routes>
                </div>
            </div>
        </div >
    );
};
export default React.memo(Auth);